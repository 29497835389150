/* StripeCheckoutForm.css */
.payment-container {
  max-width: 500px;
  margin: auto;
  background: #f6f9fc;
  padding: 5px;
  border-radius: 8px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
}

.form-row {
  margin-bottom: 20px;
}

.form-row label {
  display: block;
  color: #6b7c93;
  font-weight: 300;
}

.form-input {
  width: 100%;
  padding: 10px 14px;
  font-size: 16px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  background-color: white;
  margin-top: 5px;
}

.card-element {
  padding: 10px 14px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  background-color: white;
}

.submit-button {
  background-color: #00a4e4; /* Azul de la imagen */
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.25);
  width: 100%;
}

.submit-button:hover {
  background-color: #008CBA; /* Un tono más oscuro de azul */
}

.input-field, .StripeElement {
  font-size: 16px;
  width: 100%;
  padding: 10px 14px;
  margin-bottom: 20px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  box-sizing: border-box; /* Esto asegura que el padding no aumente el tamaño del campo */
}

/* Puedes agregar esto para asegurarte de que el tamaño de fuente sea el mismo en todos los navegadores */
.StripeElement {
  font-family: 'Helvetica Neue', Helvetica, sans-serif;
  font-size: 16px;
  line-height: 1.4em; /* 1.4em es aproximadamente 20px si el tamaño de fuente es 16px */
}

.product-details {
  text-align: center; 
}

.price-title {
  margin: 0px;
}

.price {
  font-size: 1.5em;
  font-weight: bold;
  margin: 0px;
}

.price-description {
  font-size: 1em;
  margin: 0px;
}

.powered-by-stripe {
  text-align: center;
  margin: 0px;
}

.payment-element-container {
  max-height: 55vh;
  overflow-y: auto;
  padding: 10px; 
}

@media (max-width: 600px) {
  .payment-element-container {
    max-height: 45vh; 
  }
}
